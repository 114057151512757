<template>
<div class="main">
    <span><h1>Media Library</h1></span>
    <base-dialog :show="!!error" title="An error occurred!" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-card v-if="isLoggedIn">
      <MediaForm @save-data="saveData" @update-data="updateData" 
            :title='title'
            :description='description'
            :type='type'
            :id="id" 
            />
    </base-card>
    
    <section class="sort">
        <base-card>
            <div>
                <form @submit.prevent="">
                    <div class="search">
                        <div class="search">
                            <dir class="material-icons-outlined searchIcon">search</dir><input type="text" v-model.trim="searchText" @keyup.enter="submitForm"> 
                        </div>
                         <div>
                            <button @click.prevent="sort('book')" class="material-icons-outlined buttonIcon" :class="[incBook ? 'true' : 'false']">book</button>
                            <button @click.prevent="sort('movie')" class="material-icons-outlined buttonIcon" :class="[incMovie ? 'true' : 'false']">movie</button>                                
                        </div>
                    </div>
                </form>
            </div>
        </base-card>
        <base-card>

        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else-if="hasMedia" class="row">
            <MediaItem @delete-data="deleteData" @update-data="setFormData"
                v-for="item in filteredMedia"
                :key="item.id"
                :media="item" />
        </div>
        <h3 v-else>No Media</h3>
        </base-card>
    </section>
</div>
  
</template>

<script>
// import { ref } from '@vue/reactivity'
import MediaItem from '../../components/MediaLib/MediaItem.vue'
import MediaForm from '../../components/MediaLib/MediaForm'
import { computed, reactive, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'


export default {
    components:{
        MediaItem,
        MediaForm
    },
    emits:['fillFom'],
    setup(props, context){
        const isLoading = ref(false)
        const error = ref(null)
        const store = useStore()
        const title = ref('')
        const description = ref('')
        const type = ref('')
        const id = ref('')
        const incBook = ref(true)
        const incMovie = ref(true)
        const activeFilters = reactive({
            book:true,
            movie:true
        })
        const searchText = ref('')

        const sort = (type) =>{
            if (type === 'book'){
                incBook.value = !incBook.value
                activeFilters.book = !activeFilters.book
                // alert(incBook.value)
            }else{
                incMovie.value = !incMovie.value
                activeFilters.movie = !activeFilters.movie
                // alert(incMovie.value)
            }
        }

        const loadMedia = async (refresh = false)=>{
            isLoading.value = true
            try{
                await store.dispatch('media/loadMedia', {
                    forceRefresh:refresh
                })
            }catch(err){
                error.value = err.message || 'Something Went Wrong'
            }
            isLoading.value = false
        }
        const isLoggedIn =  computed(() => {
            return store.getters.isAuthenticated
        })
        const filteredMedia = computed(function(){
            const media = store.getters['media/media']
            return media.filter(item => {
               if(activeFilters.movie && item.type ==='Movie' && item.title.toLowerCase().includes(searchText.value.toLowerCase())){
                    return true
                }
                if(activeFilters.book && item.type ==='Book' && item.title.toLowerCase().includes(searchText.value.toLowerCase())){
                    return true
                }
                return false
            })
            

        })
        const hasMedia = computed(() => {
            return !isLoading.value && store.getters['media/hasMedia']
        })
        async function saveData(data){
            await store.dispatch('media/createMedia', data)
            title.value = ''
            description.value = ''
            type.value = ''
            loadMedia(true)  
        }
        async function updateData(data){
            await store.dispatch('media/updateMedia', data)
            id.value = ''
            title.value = ''
            description.value = ''
            type.value = ''
            loadMedia(true) 
        }
        function deleteData(data){
            store.dispatch('media/deleteMedia', data)
        }
        const setFormData = (data) => {
            // console.log('data',data);
            title.value = data.title
            description.value = data.description
            type.value = data.type
            id.value = data.id
            context.emit('update-form')
        }
        const handleError = () =>{
            error.value = null;
        }
        
        loadMedia()
        return {handleError, MediaForm, MediaItem, saveData, deleteData, updateData, 
        setFormData, filteredMedia, hasMedia, loadMedia, isLoading, title, description, 
        type, id, isLoggedIn, error, sort, incBook, incMovie, searchText}
    }
}
</script>

<style scoped>

/* Clear floats after the columns */
input{
    border-color: black;
    /* font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 14px; */
    font: inherit;
    margin: 20px 0px 0px 0px;
}
.search{
    display: flex;
    padding: auto;
    justify-content: space-between;
}
.searchIcon{
    margin: 20px 0px 0px 0px;
}
.true{
    border: 2px solid cornflowerblue;
}
.false{
    border: 2px solid rgb(255, 255, 255);
}
.row{
    padding: 20px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.controls {
  display: flex;
  justify-content: space-between;
}
.sort{
    text-align: right;
}
.buttonIcon{
    margin: 3px;
}

</style>