export default {
    addMedia(state, payload){
        state.media.push(payload)
    },
    deleteMedia(state, payload){
        const index = state.media.findIndex(media => media.id == payload);
        state.media.splice(index, 1);
    },
    setMedia(state, payload){
        state.media = payload
    },
    setFetchTimestamp(state) {
      state.lastFetch = new Date().getTime();
    }
}