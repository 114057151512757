import { createStore } from 'vuex'

import mediaModule from './media/index.js';
import authModule from './auth/index.js';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    media : mediaModule,
    auth : authModule
  }
})
