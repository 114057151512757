import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MediaList from '../views/MediaLib/MediaList'
import MediaForm from '../views/MediaLib/MediaForm'
import GameList from '../views/Games/GameList'
import UserAuth from '../views/Auth/UserAuth';
import NotFound from '../views/NotFound';
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/media',
    name: 'Media',
    component: MediaList
  },
  {
    path: '/addmedia',
    name: 'MediaForm',
    component: MediaForm,
    meta: { requiresAuth:true}
  },
  {
    path: '/games',
    name: 'Games',
    component: GameList
  },
  {
    path: '/auth',
    name: 'Auth',
    component: UserAuth
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { path: '/:notFound(.*)', 
    component: NotFound }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(function(to, _, next){
  if(to.meta.requiresAuth && !store.getters.isAuthenticated){
    next('/auth')
  }else if(to.meta.requiresUnauth && store.getters.isAuthenticated){
    next('/')
  }else{
    next()
  }
})
export default router
