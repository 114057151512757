<template>
  <section>
    <base-card>
      <h2>Add New Medai!</h2>
      <MediaForm @save-data="saveData" ></MediaForm>
    </base-card>
  </section>
</template>

<script>
import MediaForm from '../../components/MediaLib/MediaForm'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    components:{
        MediaForm
    },
    setup(){
        const store = useStore()
        const router = useRouter()
        
        function saveData(data){
            store.dispatch('media/createMedia', data)
            router.push('/')
        }
        function updateData(data){
            store.dispatch('media/updateMedia', data)
            router.push('/')
        }

        return { saveData, updateData }
    }

}
</script>

<style>

</style>