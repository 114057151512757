export default{
    media(state){
        return state.media
    },
    hasMedia(state){
        return state.media && state.media.length > 0
    },
    shouldUpdate(state) {
      const lastFetch = state.lastFetch;
      if (!lastFetch) {
        return true;
      }
      const currentTimeStamp = new Date().getTime();
      return (currentTimeStamp - lastFetch) / 1000 > 60;
    }
}