<template>
  <div class="home">
    <!-- <h1> This is the CyberShyft Home Page</h1> -->
    <img src="../assets/banner-2.jpg" alt="">
   
  </div>
</template>

<script>
export default {
  name:'Home',
      setup() {

      }
}
</script>
