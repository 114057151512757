<template>
  <header>
    <nav>
      <h1 class="company">
        <router-link to="/">CYBERSHYFT</router-link>
      </h1>
      <ul>
        <li>
          <router-link to="/media">Media Library</router-link>
        </li>
        <li>
          <router-link to="/games">Games</router-link>
        </li>
        <li v-if="isLoggedIn">
          <base-button @click="logout">Logout</base-button> 
        </li>
        <li v-else>
          <router-link to="/auth">Login</router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>

export default {
  setup(){

  },
  computed:{
    isLoggedIn(){
      return this.$store.getters.isAuthenticated
    }
  },
  methods:{
    logout(){
      this.$store.dispatch('logout')
      this.$router.replace('/')
    }
  }
}
</script>


<style scoped>
header {
  width: 100%;
  height: 5rem;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

header a {
  text-decoration: none;
  color: #9198f3;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid #9198f3;
}

h1 {
  margin: 0;
}

h1 a {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company{
  font-family: Oxanium;
}
li {
  margin: 0 0.5rem;
}
</style>