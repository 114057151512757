<template>
  <div  class="single column">
    <div class="icon">
      <div class="material-icons-outlined">{{ media.type.toLowerCase() }}</div>
      <div class="image"><img v-if="media.type === 'Movie'" :src="media.image" alt="movie poster"></div>
    </div>
    <div class="title">
      <h3>{{media.title}}</h3>
      <div v-if="media.type === 'Book'">
        <h4 >{{media.description}}</h4>
      </div>
      <div v-else>
        <h4 class="plot">{{media.plot}}</h4>
        <h5>Rated:{{media.rated}} - {{media.rating}} Stars - {{media.releaseDate}}</h5>
      </div>
      
    </div>
    <div class="mod" v-if="isLoggedIn">
      <button @click="deleteMedia(media.id)" class="material-icons-outlined">delete</button>
      <button @click="updateMedia(media.id)" class="material-icons-outlined">edit</button>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
    props: ['media'],
    emits:['delete-data', 'update-data'],
    setup(props, context){
      const store = useStore()
      const isLoggedIn = computed(() => {
        return store.getters.isAuthenticated
      })

      const deleteMedia = () => {
        context.emit('delete-data', props.media.id)
      }
      const updateMedia = () => {
        context.emit('update-data', props.media)
      }
      return {deleteMedia, updateMedia, isLoggedIn }
    }
}
</script>

<style scoped>
.single{
    border: 2px solid #bdbcbc;
    align-items: center;
    padding: .5rem;
    border-radius: 12px;
    background: white;
    margin: 8px 0;
    transition: all ease 0.2s;
    display: flex;
    min-height: 200px;
}
.single:hover{
    box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
    transform: scale(1.03);
    transition: all ease 0.2s;
}
.column {
  float: left;
  width: 37.2rem;
  height: auto;
  margin: 3px;
}
.plot{
  max-height: 4em;
  overflow: auto;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.5rem 0;
  padding: 0 .5rem 0 0;
}
img {
  max-height: 84px;
  max-width: 66px;
}
.mod{
  width: 50px;
  vertical-align: middle;
}
.icon{
  vertical-align: auto;
}
.actions {
  display: flex;
  justify-content: flex-end;
}
.title{
  width: 100%;
  text-align: left;
}
.image{
  max-height: 84px;
  max-width: 66px;
}
/* ICONS */
.material-icons-outlined{
    vertical-align: top;
    margin-top: 4px;
    margin-right: 4px;
    margin-bottom: 8px;
  }
</style>