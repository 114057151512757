<template>
  <TheHeader />
  <router-view />
</template>

<script>
import { useStore } from 'vuex'
import { computed, watch } from 'vue'

import TheHeader from './components/Layout/TheHeader.vue'
import { useRouter } from 'vue-router'
export default {
  components:{
    TheHeader
  },
  setup(){
    const store = useStore()
    const router = useRouter()
    const didAutoLogout = computed(()=>{
      return store.getters.didAutoLogout
    })

    store.dispatch('autoLogin')

    watch(didAutoLogout, function(curVal, oldVal){
        if(curVal && curVal !== oldVal){
          router.replace('/')
      }
    })
    return { didAutoLogout   }
  },
  created(){
    this.$store.dispatch('autoLogin')
  }

}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

</style>
