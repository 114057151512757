export default {
    async createMedia(context, payload){
        let mId = ''
        if(payload.type === 'Movie'){
          const IMDBData = await context.dispatch('getMediaKeyFromIMDB',payload.title)
          mId = await IMDBData.responseData.results[0].id
        }
        
        const  newMedia = {
            mediaTitle: payload.title,
            mediaDesc: payload.description,
            mediaType: payload.type,
            mediaId:mId
          };
          const response = await fetch('https://cybershyft-7b3f5-default-rtdb.firebaseio.com/media.json', {
            method: 'POST',
            body: JSON.stringify(newMedia)
          });

          const responseData = await response.json();
          if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to send request.');
            throw error;
          }
          // console.log('google', responseData);
          newMedia.id = responseData.name;
      
          context.commit('addMedia', newMedia);
    },
    async updateMedia(context, payload){
      let mId=''
      if(payload.type === 'Movie'){
        const IMDBData = await context.dispatch('getMediaKeyFromIMDB',payload.title)
        mId = await IMDBData.responseData.results[0].id
        //console.log('IMDB ',IMDBData.responseData.results[0].id);
        // responseData.searchType
      }
      const newMedia = {
          id: payload.id,
          mediaTitle: payload.title,
          mediaDesc: payload.description,
          mediaType: payload.type,
          mediaId:mId
        };

        const response = await fetch(`https://cybershyft-7b3f5-default-rtdb.firebaseio.com/media/${payload.id}.json`, {
          method: 'PUT',
          body: JSON.stringify(newMedia)
        });

        const responseData = await response.json();
        if (!response.ok) {
          const error = new Error(responseData.message || 'Failed to send request.');
          throw error;
        }   
        
  },
    async deleteMedia(context, payload){
        const response = await fetch(`https://cybershyft-7b3f5-default-rtdb.firebaseio.com/media/${payload}.json`, {
          method: 'DELETE',
        });

        const responseData = await response.json();
        if (!response.ok) {
          const error = new Error(responseData.message || 'Failed to send request.');
          throw error;
        }
   
        context.commit('deleteMedia', payload);
  },
    
    async loadMedia(context, payload){
        
        if (!payload.forceRefresh && !context.getters.shouldUpdate) {
            return;
        }
        const response = await fetch(
            'https://cybershyft-7b3f5-default-rtdb.firebaseio.com/media.json'
        );
        const responseData = await response.json();
      
        if (!response.ok) {
          const error = new Error(responseData.message || 'Failed to fetch!');
          throw error;
        }
      
        const media = [];
        const promises = Object.keys(responseData).map(key => {
          const IMDBData = context.dispatch('getMediaInfoFromIMDB',responseData[key].mediaId)
          return IMDBData
        })

        const prom = await Promise.all(promises)

        for(const key in prom){
          if(prom[key].responseData.id){
            const item = {
              id: prom[key].responseData.id,
              title: prom[key].responseData.title,
              description: prom[key].responseData.description,
              type: prom[key].responseData.type,
              mediaId:prom[key].responseData.id,
              plot:prom[key].responseData.plot,
              rated:prom[key].responseData.contentRating,
              rating:prom[key].responseData.imDbRating,
              image:prom[key].responseData.image,
              releaseDate:prom[key].responseData.releaseDate,
            }
            media.push(item);
          }
        }
        for (const key in responseData) {
          if (responseData[key].mediaType === 'Book'){
            const item = {
              id: key,
              title: responseData[key].mediaTitle,
              description: responseData[key].mediaDesc,
              type: responseData[key].mediaType,
              mediaId:responseData[key].mediaId
            }
            media.push(item);
          }
        }

          media.sort(function(a, b){
            if(a.title < b.title) { return -1; }
            if(a.title > b.title) { return 1; }
            return 0;
        })
          context.commit('setMedia', media);
          context.commit('setFetchTimestamp');
    },
    async getMediaKeyFromIMDB(context, payload){
      const link = 'https://imdb-api.com/en/API/SearchMovie/k_4a3nkj9z/' + payload
      //console.log('link', link);
      const response = await fetch(
        link
      );
      const responseData = await response.json();
  
      if (!response.ok) {
        const error = new Error(responseData.message || 'Failed to fetch!');
        throw error;
      }
        
        return{ responseData }
    },
    async getMediaInfoFromIMDB(context, payload){
      const link = 'https://imdb-api.com/en/API/Title/k_4a3nkj9z/' + payload
      const response = await fetch(
        link
      );
      const responseData = await response.json();
  
      if (!response.ok) {
        const error = new Error(responseData.message || 'Failed to fetch!');
        throw error;
      }
        return{ responseData }
    }
}