<template>
    <div>
        <base-dialog :show="!!error" title="An Error Occurred" @close="handleError">
        <p>{{error}}</p>
        </base-dialog>
        <base-dialog :show="isLoading" title="Authenticating..." fixed>
        <base-spinner></base-spinner>
        </base-dialog>
        <base-card>
        <form @submit.prevent="submitForm">
            <div class="form-control">
                <label for="email">E-Mail</label>
                <input type="email" id="email" v-model.trim="email">
            </div>
            <div class="form-control">
                <label for="password">Password</label>
                <input type="password" id="password" v-model.trim="password">
            </div>
            <p v-if="!formIsValid">Dude! Put some effort into it.</p>
            <base-button>{{submitButtonCaption}}</base-button>
        </form>
        </base-card>
    </div> 
</template>

<script>
export default {
    data(){
        return {
            email: '',
            password: '',
            formIsValid: true,
            mode: 'login',
            isLoading: false,
            error: null
            }
    },
    computed:{
        submitButtonCaption(){
            if(this.mode === 'login'){
                return 'Login'
            }else{
                return 'Signup'
            }
        },
        switchModeButtonCaption(){
            if(this.mode === 'login'){
                return 'Signup instead'
            }else{
                return 'Login instead'
            }
        }
    },
    methods:{
        handleError(){
            this.error = null
        },
        async submitForm(){
            this.formIsValid = true
            if(this.email === '' || !this.email.includes('@') || this.password.length < 6 ){
                this.formIsValid = false
                return
            }
            const actionPayload = {
                email: this.email,
                password: this.password
            }
            this.isLoading = true
            try{
                if(this.mode === 'login'){
                    await this.$store.dispatch('login', actionPayload)  
                }else{
                    await this.$store.dispatch('signUp',actionPayload)
                }
                const redirectUrl = '/' 
                this.$router.replace(redirectUrl)
            }catch(err){
                this.error = err.message || 'No Bueno'
            }
            this.isLoading = false
        },
        switchAuthMode(){
            if(this.mode === 'login'){
                this.mode = 'signup'
            }else{
                this.mode = 'login'
            }
        }
    }
}
</script>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}

.errors {
  font-weight: bold;
  color: red;
}

.actions {
  text-align: center;
}
</style>