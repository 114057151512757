<template>
  <form @submit.prevent="submitForm">
    <div class="form-control" :class="{invalid: !vType}" >
        <input type="radio" name="type" value="Book" v-model="tempType">
        <label for="type">Book </label>
        <input type="radio" name="type" value="Movie" v-model="tempType">
        <label for="type">Movie</label>
      </div>
      <p v-if="!vType">Please select the media type.</p>
      <div class="form-control" :class="{invalid: !vTitle}">
          <label for="title">Title:</label>
          <input type="text" id="title" v-model.trim="tempTitle"  >
      </div>
      <p v-if="!vTitle">Please enter media title.</p>
      <div class="form-control" :class="{invalid:!vDescription}" v-if="tempType === 'Book'">
          <label for="description">Description:</label>
          <textarea  rows="3" id="description" v-model.trim="tempDescription"></textarea>
      </div>
      <p v-if="!vDescription && tempType ==='Book'">Please enter media description.</p>
      <!-- <base-button>Add Media</base-button> -->
  </form>
</template>

<script>
import { ref, watch } from '@vue/runtime-core'
export default {
    props: ['title', 'description', 'type', 'id'],
    emits:['save-data'],

    setup(props, context){
        const tempTitle = ref('')
        const tempType = ref('')
        const tempDescription = ref('')
        const vTitle = ref(true)
        const vType = ref(true)
        const vDescription = ref(true)
        const formIsValid = ref(true)
        let tempId = ''

        watch(props,function(post){
          tempTitle.value = post.title
          tempType.value = props.type
          tempDescription.value = props.description
          tempId = props.id
        })
        function viewData(){
          console.log('Title1',props.title, tempDescription.value);
          console.log('tempId', tempId);
        }

        const validateForm = () => {
          formIsValid.value = true
          if(tempType.value === ''){
            vType.value = false
            formIsValid.value = false
          }else{
            vType.value = true
          }
          if(tempTitle.value === ''){
            vTitle.value = false
            formIsValid.value = false
          }else{
            vTitle.value = true
          }
          if(tempDescription.value === '' && tempType.value.toLowerCase() === 'book'){
            vDescription.value = false
            formIsValid.value = false
          }else{
            vDescription.value = true
          }
        }

        const submitForm = () => {
          validateForm()
          if(!formIsValid.value){ 
            return
          }
            const formData = {
                title:tempTitle.value,
                description:tempDescription.value,
                type:tempType.value,
                id:tempId
            }
            tempTitle.value = ''
            tempType.value = ''
            tempDescription.value = ''
            if(!tempId){  
                context.emit('save-data', formData)
            }else{
                context.emit('update-data', formData)
            }
            
        }
        return { submitForm, viewData, tempType, tempDescription, tempTitle, vType, vDescription, vTitle, tempId, formIsValid }
    }

}
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
  text-align: left;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type='radio'] + label {
  text-align: center;
  font-weight: bold;
  display: inline;
  width: auto;
  margin: 0 0 0 0.5rem;
}

input[type='radio'] {
  text-align: center;
  display: inline;
  width: auto;
  border: none;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type='radio']:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>