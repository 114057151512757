let timer

export default {
    async login(context, payload){
        return context.dispatch('auth', {
            ...payload,
            mode: 'login'
        }) 
    },

    async signUp(context, payload){
        return context.dispatch('auth', {
            ...payload,
            mode: 'signup'
        }) 
    },
    async auth(context, payload){
        const mode = payload.mode
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCMEjYYa9KdJYYVYTZ8XUFyHapf02-gg9o'
        if (mode === 'signup'){
            url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCMEjYYa9KdJYYVYTZ8XUFyHapf02-gg9o'
        }
        const response = await fetch(url, {
            method:'POST',
            body: JSON.stringify({
                email:payload.email,
                password: payload.password,
                returnSecureToken: true
            })
        })
        const responseData = await response.json()

        if (!response.ok){
            const error = new Error(responseData.mesage || 'Failed to Authenticate!')
            throw error
        } 
        const expiresIn = +responseData * 1000
        const expirationDate = new Date().getTime() + expiresIn
        localStorage.setItem('token', responseData.idToken)
        localStorage.setItem('userId', responseData.localId)
        localStorage.setItem('tokenEexpiration',expirationDate)

        timer = setTimeout(function(){
            context.dispatch('AutoLogout')
        },expiresIn)

        context.commit('setUser', {
            token: responseData.idToken,
            userId: responseData.localId
        })
    },
    autoLogin(context){
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId')
        const tokenEexpiration = localStorage.getItem('tokenEexpiration')

        const expiresIn = +tokenEexpiration - new Date().getTime()

        if(expiresIn < 1){
            return
        }

        // timer = setTimeout(function(){
        //     context.dispatch('autoLogout')
        // }, expiresIn)

        if(token && userId){
            context.commit('setUser', {
                token: token,
                userId: userId
            })
        }
    },
    
    logout(context){
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        localStorage.removeItem('tokenExpiration')

        clearTimeout(timer)

        context.commit('setUser', {
            token: null,
            userId: null
        })
    },
    autoLogout(context){
        context.dispatch('logout')
        context.commit('setAutoLogout')
    }
}