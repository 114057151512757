import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state(){
        return{
            lastFetch: null,
            media:[
                {
                    id: 'a1b2',
                    title: 'Oscar',
                    description: 'Sylvester Stallone playes Oscar the gangster',
                },
                {
                    id: 'a1b3',
                    title: 'The Patriot',
                    description: 'Mel plays a real American patriot',
                }
            ]
        }
    },
    mutations,
    actions,
    getters
}